import revive_payload_client_4sVQNw7RlN from "/AuraTour/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/AuraTour/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/AuraTour/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import vuetify_L6J0nJ2Nk3 from "/AuraTour/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify.mts";
import payload_client_yVLowv6hDl from "/AuraTour/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/AuraTour/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/AuraTour/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/AuraTour/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/AuraTour/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/AuraTour/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_bKSoGl1L7p from "/AuraTour/node_modules/yandex-metrika-module-nuxt3/dist/runtime/plugin.mjs";
import nuxt3_plugin_BdfRvWAAOY from "/AuraTour/node_modules/vue-yandex-maps/dist/plugins/nuxt3-plugin.js";
import plugin_ghbUAjaD3n from "/AuraTour/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import vuetify_icons_FCTj4zkEbu from "/AuraTour/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-icons.mts";
import auth_vT9JWWT9pN from "/AuraTour/plugins/auth.ts";
import calendar_client_i1uzu9e7Tc from "/AuraTour/plugins/calendar.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  vuetify_L6J0nJ2Nk3,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_bKSoGl1L7p,
  nuxt3_plugin_BdfRvWAAOY,
  plugin_ghbUAjaD3n,
  vuetify_icons_FCTj4zkEbu,
  auth_vT9JWWT9pN,
  calendar_client_i1uzu9e7Tc
]